import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Hero from '../components/Hero'
import logo_jandJ from "../images/Logo_jandj.png"
import logo_medtronic from "../images/Logo_medtronic.svg"
import logo_aws from "../images/Logo_aws.png"
import logo_microsoft from "../images/Logo_microsoft.svg"
import logo_amorphic from "../images/Logo_amorphic.svg"
import logo_mirrorweb from "../images/Logo_mirrorweb.svg"
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { JsonLd } from "react-schemaorg";
import { BreadcrumbList } from "schema-dts";

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "tower_hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth:1600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ({data}) => {

  return (
  <>
   <JsonLd<BreadcrumbList>
      item={{
        "@context": "https://schema.org",
        "@type": "BreadcrumbList", 
      "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "PanSurg",
      "item": "http://www.pansurg.org"  
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": "About",
      "item": "http://www.pansurg.org/about"  
    }] }} />

<GatsbySeo title='About PanSurg'
      description='Who PanSurg are, what we do and who we work with'/>
  <Hero title="About PanSurg"
        subtitle="Who we are, what we do and who we work with"
        fluid={data.hero.childImageSharp.fluid}
        breadcrumbs
        activecrumb = 'About'
  />

  <section className="section is-small" style={{margin:'2rem 0rem'}}>

    <div className="container ">

      <div className="content is-thin">
        <p>PanSurg is a direct response to the threat the COVID-19 pandemic poses to
        patients with surgical pathology and the people that look after them. We're a group of clinicians, surgeons and academics from the <a href="https://www.imperial.ac.uk/department-surgery-cancer">Department of Surgery and Cancer</a> and the <a href="http://www.imperial.ac.uk/global-health-innovation/">Institute of Global Health Innovation</a>, Imperial College London. We have a
        wide experience in delivering projects aimed at transforming health for
        all through evidence-based innovation.</p>

        <p> But PanSurg is not about us. PanSurg may have started in the UK but it needs to be a global
        collaborative reach its potential.
        </p>

      <p>
      Please join our <a href="#mailinglist">mailing list</a> for updates
        on how you can get involed.
      </p>

      </div>
    </div>
    <br />
    <hr className="is-divider"/>
    <br />
    <div className="container">
      <p className="subtitle is-size-4-mobile has-text-darkBlue">PanSurg Collaborative</p>
      <div className="about-people is-flex-tablet">

      <div>
        <p><strong>Operations</strong></p>
        <ul style={{textIndent:'1rem'}}>
          <li><strong>PIs:</strong><a href="mailto:j.kinross@imperial.ac.uk"> James Kinross</a> and <a href="mailto:s.purkayastha@imperial.ac.uk"> Sanjay Purkayastha</a></li>
          <li><a href="mailto:as703@ic.ac.uk">Alasdair Scott</a></li>
        </ul>

        <br />

        <p><strong>Communications</strong></p>
        <ul style={{textIndent:'1rem'}}>
          <li><a href="mailto:j.winter-beatty@imperial.ac.uk"><strong>Lead:</strong> Jasmine Winter Beatty</a></li>
          <li>Nikita Rathod</li>
          <li>Rabiya Aseem</li>
          <li>Emily Deurloo</li>
          <li>Andrew Yiu</li>
          <li>Nicola Quinnen</li>
          <li>NIna De la Cruz</li>
          <li>Natasha Khan</li>

        </ul>

        <br />

        <p><strong>Web Development</strong></p>
        <ul style={{textIndent:'1rem'}}>
          <li><a href="mailto:as703@ic.ac.uk"><strong>Lead:</strong> Alasdair Scott</a></li>
          <li>Ayush Kulshreshtha</li>
          <li>Maxwell Flitton</li>
        </ul>

        <br />

        <p><strong>Data Analytics</strong></p>
        <ul style={{textIndent:'1rem'}}>
          <li><a href="mailto:j.clarke@imperial.ac.uk"><strong>Lead:</strong> Jonathan Clarke</a></li>
        </ul>

      </div>

      <br />

      <div>

        <p><strong>Research Governance</strong></p>
        <ul style={{textIndent:'1rem'}}>
          <li><a href="mailto:guy.martin@imperial.ac.uk"><strong>Lead:</strong> Guy Martin</a></li>
        </ul>

        <br />

        <p><strong>Academy</strong></p>
        <ul style={{textIndent:'1rem'}}>
          <li><a href="mailto:s.yalamanchili@imperial.ac.uk"><strong>Lead:</strong> Seema Yalamanchili</a></li>
          <li>Alasdair Scott</li>
          <li>Guy Martin</li>
          <li>Roger van Schie</li>
          <li>Rabiya Aseem</li>
          <li>Liam Poynter</li>
          <li>Ravi Aggarwal</li>
          <li>Simon Dryden</li>
          <li>Ola Markiewicz</li>
        </ul>

        <br />

        <p><strong>Translational Science</strong></p>
        <ul style={{textIndent:'1rem'}}>
          <li><a href="mailto:sam.mason@imperial.ac.uk"><strong>Lead:</strong> Sam Mason</a></li>
          <li>Piers Boshier</li>
        </ul>

      </div>

      <br />

      <div>
        <p><strong>PREDICT</strong></p>
        <ul style={{textIndent:'1rem'}}>
          <li><a href="mailto:s.markar@imperial.ac.uk"><strong>Lead:</strong> Sheraz Markar</a></li>
          <li>Guy Martin</li>
          <li>Jonathan Clarke</li>
          <li>Jasmine Winter Beatty</li>
          <li>Maxwell Flitton</li>
          <li>Viknesh Sounderajah</li>
          <li>Leigh Warren</li>
          <li>Osama Moussa</li>
        </ul>

        <br />

        <p><strong>SSAFE</strong></p>
        <ul style={{textIndent:'1rem'}}>
          <li><a href="mailto:max.denning18@imperial.ac.uk"><strong>Lead:</strong> Max Denning</a></li>
          <li>Viknesh Sounderajah</li>
          <li>Guy Martin</li>
          <li>Ee Teng Goh</li>
          <li>Jonathan Clarke</li>
          <li>Melanie Almonte</li>
          <li>Swathikan Chadminbaran</li>
          <li>Jan Przybylowicz</li>
        </ul>
      </div>

      <br />

      <div>
      <p><strong>Data Synthesis</strong></p>
      <ul style={{textIndent:'1rem'}}>
        <li><a href="mailto:s.markar@imperial.ac.uk"><strong>Lead:</strong> Sheraz Markar</a></li>
        <li>Guy Martin</li>
        <li>Jonathan Clarke</li>
        <li>Viknesh Sounderajah</li>
        <li>Sanjay Purkayastha</li>
        <li>Swathikan Chidambaram</li>
        <li>Simon Erridge</li>
        <li>Uddhav Vaghela</li>
        <li>Simon Rabinowicz</li>
        <li>Paris Bratsos</li>
      </ul>

      <br />

      <p><strong>SENTINEL</strong></p>
      <ul style={{textIndent:'1rem'}}>
        <li><a href="mailto:s.yalamanchili@imperial.ac.uk"><strong>Lead:</strong> Seema Yalamanchili</a></li>
        <li>Ovidiu Serban</li>
      </ul>
    </div>


      </div>
    </div>
  </section>

  <hr className="is-divider"/>

  <section className="section is-small" >
    <div className="container">
       <div className="is-thin">

    <p className="subtitle has-text-darkBlue">Industry Partner</p>

    <div className="logos is-flex-tablet has-text-centered">
      <a href=" https://www.jnjmedicaldevices.com/en-GB"><img width="400px" src={logo_jandJ} /></a>
    {/*  <a href="https://www.medtronic.com"><img width="200px" src={logo_medtronic} /></a> */}
    </div>

    <p className="subtitle has-text-darkBlue">Industry Collaborators</p>
    <div className="logos is-flex-tablet has-text-centered" style={{justifyContent:'space-between'}}>
    <a href="https://aws.amazon.com"><img width="100px" style={{marginRight:20}} src={logo_aws} /></a>
    <a href="https://www.microsoft.com/en-gb"><img width="200px" src={logo_microsoft} /></a>
    <a href="https://www.mirrorweb.com/"><img width="200px" src={logo_mirrorweb} /></a>
    <a href="https://www.amorphicdata.com/"><img width="200px" src={logo_amorphic} /></a>
    </div>

      </div>
    </div>
  </section>

  </>
  )
}
